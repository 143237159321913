import React, { useEffect, useRef } from "react"
import Seamless from "../../components/SeamlessEmbed"

const LocationForms = ({ activeForm }) => {
  if (!activeForm.newForm) return ""

  const formContainer = useRef()

  useEffect(() => {
    if (activeForm.newForm) {
      const form = document.getElementById("contact-page-form")
      setTimeout(() => {
        window.scroll({ top: form.offsetTop - 88, left: 0, behavior: "smooth" })
      }, 600)
    }
  }, [activeForm.newForm])

  return (
    <div className="columns contact-bottom location-contact-form">
      <div className="column is-4"></div>
      <div className="column">
        <center>
          <div style={{ maxWidth: "1000px" }}>
            <div id="contact-page-form">
              {activeForm.newForm && <h3>Send us a Message</h3>}
              <p className="required-fields">
                <span className="required-asterisk">*</span>
                Required Fields
              </p>
            </div>
            <div
              style={{
                minHeight: activeForm.newForm ? "600px" : 0
              }}
              ref={formContainer}
              className="seamless-form-wrapper"
              id="seamless-form-wrapper">
              <Seamless id={activeForm.newForm} />
            </div>
          </div>
        </center>
      </div>

      <div className="column is-4"></div>
    </div>
  )
}

export default LocationForms
